import React, { useEffect, useState } from "react";
import CashierIcon from "../assets/svg/CashierIcon";
import GamesIcon from "../assets/svg/GamesIcon";
import HomeIcon from "../assets/svg/HomeIcon";
// import RiExchangeFill from "../assets/svg/ProfileIcon";
import SportsIcon from "../assets/svg/SportsIcon";
import Exchange from "../assets/images/Exchange.png";
import Whatsapp from "../assets/images/Whatsapp.svg";
import Telegram from "../assets/images/Telegram.svg";
// import Facebook from "../assets/images/Facebook.svg";
import Instagram from "../assets/images/Instagram.svg";
// import logo from "../assets/svg/logo_new.png";

import RNG from "../assets/images/providers/rng-certified.png";
import Gambling from "../assets/images/providers/gambling-commission.png";
import ResponsibleG from "../assets/images/providers/responsible-gaming.png";
import Gamble18 from "../assets/images/providers/gamble-resp-18.png";
import PaymentMod from "../assets/images/providers/payment-option.png";
import Betfair from "../assets/images/providers/betfair.png";

import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { CasinoProviders } from "../lib/data";
const Footer = () => {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);
  const location = useLocation();
  const [activePage, setActivePage] = useState("");

  const footerPages = [
    "home",
    "sportsbook",
    "casino/evolution",
    "casino/ezugi",
    "deposit",
    "exchange",
  ];
  useEffect(() => {
    console.log("location", location.pathname);
    const matchedValue = footerPages.find((f) =>
      location?.pathname.includes(f)
    );
    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);

  return (
    <footer className="footer">
      <div className="container">
        <div className="providers_img">
          <a href="#" style={{ width: "65px" }}>
            <img src={RNG} loading="lazy" alt="RNG Certified" />
          </a>
          <a href="#" style={{ width: "80px" }}>
            <img src={Gambling} loading="lazy" alt="Gambling Commission" />
          </a>
          <a href="#" style={{ width: "130px" }}>
            <img src={ResponsibleG} loading="lazy" alt="Responsible Gaming" />
          </a>
          <a href="#" style={{ width: "120px" }}>
            <img src={Gamble18} loading="lazy" alt="Gamble Responsible" />
          </a>
        </div>

        <div className="helpline">
          {" "}
          <a
            className="panel social-icon watsapp"
            href={
              appDetails?.WHATS_APP_URL != "" ? appDetails?.WHATS_APP_URL : "#"
            }
            target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
          >
            <h3 className="mb-2" style={{ cursor: "pointer" }}>
              Call Us {appDetails?.WHATS_APP_URL}
            </h3>
          </a>
        </div>

        <div className="footer-links">
          <ul>
            <li>
              <a href="/contactus">Contact Us</a>
            </li>
            <li>
              <a href="/privacypolicy ">Privacy Policy</a>
            </li>
            <li>
              <a href="/responsiblegame">Responsible Gaming</a>
            </li>
            <li>
              <a href="/fairplay">Fair Play</a>
            </li>
            <li>
              <a href="/gamesrule">Games Rules</a>
            </li>
            <li>
              <a href="/terms">Terms and Conditions</a>
            </li>
          </ul>
        </div>

        <div className="d-flex justify-content-center mt-2">
          <p style={{ color: "rgb(204, 204, 204)" }}>
            Accept, process &amp; disburse digital payments for your business.
          </p>
        </div>
        <div className="d-flex justify-content-center mt-2">
          <img
            src={PaymentMod}
            loading="lazy"
            style={{ width: "250px" }}
            alt="Payment Otions"
          />
        </div>

        <div className="d-flex justify-content-center mt-3 mb-3">
          <img
            src={Betfair}
            loading="lazy"
            className="mb-3 betfair"
            alt="Betfair Logo"
          />
        </div>

        <p>{appDetails?.FOOTER_TEXT || ""}</p>
        <div className="paymentImages d-none">
          <ul>
            <li>
              <img src="./images/sewa.jpeg" />
            </li>
            <li>
              <img src="./images/khalti.jpeg" />
            </li>
          </ul>
        </div>

        {/* <div className="social-icons d-none">
          <ul>
            <li>
              <div
                onClick={() =>
                  window.open("https://wa.me/+917888846060", "_blank")
                }
              >
                <span className="notification-count">2</span>
                <img src={Whatsapp} alt="Whatsapp Icon" />
              </div>
            </li>
            <li>
              <a target="_blank" href="https://t.me/JuwaGhar">
                <img src={Telegram} alt="Telegram Icon" />
              </a>
            </li>
            <li>
              <a target="_blank" href="https://www.facebook.com/juwaghar">
                <img src="/images/facebook-512.svg" alt="Facebook Icon" />
              </a>
            </li>
            <li>
              <a target="_blank" href="https://www.instagram.com/JuwaGhar">
                <img src={Instagram} alt="Instagram Icon" />
              </a>
            </li>
          </ul>
        </div> */}

        <div className="footer-menu position-fixed start-0 bottom-0 z-index-2 d-block d-lg-none">
          <ul className="d-flex justify-content-evenly m-0 p-0 list-unstyled">
            <li>
              <a className={activePage === "home" ? "active" : ""} href="/home">
                <HomeIcon />
                <span>Home</span>
              </a>
            </li>
            <li>
              <a
                className={activePage === "sportsbook" ? "active" : ""}
                href="/betby"
              >
                <SportsIcon />
                <span>Sportsbook</span>
              </a>
            </li>
            <li>
              <a
                className={activePage === "exchange" ? "active" : ""}
                href="/exchange/Inplay"
              >
                <img src={Exchange} alt="Exchange Icon" width={20} />
                <span>Exchange</span>
              </a>
            </li>
            {/* <li>
              <a href="/sports">
                <img src={Exchange} alt="Exchange Icon" />
                <span>Exchange</span>
              </a>
            </li> */}
            <li>
              <a href={CasinoProviders["ezugi"]?.href}>
                <GamesIcon />
                <span>Ezugi</span>
              </a>
            </li>
            <li>
              <a href={CasinoProviders["evolution"]?.href}>
                {/* <CashierIcon /> */}
                <GamesIcon />
                <span>Evolution</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import React, { useEffect, useState } from "react";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import BackIcon from "../../../../assets/svg/BackIcon";
import { Container, Row, Col, Tabs, Tab, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getActiveBankDetails } from "../../../../redux/app/actions";
// import Whatsapp from "../assets/images/Whatsapp.svg"/;
import Whatsapp from "../../../../assets/images/Whatsapp.svg";
import { useLocation } from "react-router-dom";

import Telegram from "../../../../assets/images/Telegram.svg";
import Facebook from "../../../../assets/images/facebook-512.svg";
import Instagram from "../../../../assets/images/Instagram.svg";
import DepositImg from "../../../../assets/images/deposit-img.png";
import upiqr from "../../../../assets/images/upiqr.png";
import {
  createDeposit,
  getOnlinePaymentLink,
} from "../../../../redux/user/actions";
import { Toaster } from "../../../../utils/toaster";
import { APP_CONST } from "../../../../config/const";
import Hpay from "./hpay";
import LeftBarSports from "../../../components/LeftBarSports";

const BankDeposit = () => {
  const [amount, setamount] = useState(0);

  const location = useLocation();
  useEffect(() => {
    setamount(location.state.amount);
  }, [location]);
  console.log(location, "location");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { bankDetails } = useSelector((state) => state.app);
  const [depositFrom, setDepositForm] = useState({});
  const [Amount, setAmount] = useState({
    amount: 0,
  });

  const [formError, setFormError] = useState({});
  useEffect(() => {
    dispatch(getActiveBankDetails());
  }, []);

  const handleAmountSubmit = async (e) => {
    e.preventDefault();
    const paylink = await getOnlinePaymentLink(Amount);
    if (paylink?.payUrl) {
      window.location.href = paylink?.payUrl;
    }
  };
  const validateData = (dataToValidate) => {
    const vData = dataToValidate ? dataToValidate : depositFrom;
    const { utrNo, txnImage } = vData;

    let errors = {};
    // if (!upiamount || parseFloat(upiamount) < 500 || upiamount > 500000) {
    //   errors.upiamount = "Minimum 500 and Maxium 500000 amount can be deposit ";
    // }
    if (amount < 1 || amount > 100000) {
      errors.amount = "Minimum 1 and Maxium 100000 amount can be deposit";
    } else if (!amount) {
      errors.amount = "Amount cannot be empty";
    }
    if (!utrNo || utrNo == "") {
      errors.utrNo = "UTR number cannot be empty";
    }
    if (!txnImage) {
      errors.txnImage = "Select a file";
    }
    setFormError(errors);
    return errors;
  };
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const data = { ...depositFrom, [name]: value };
    validateData(data);
    setDepositForm(data);
  };
  const handleSubmit = () => {
    console.log("clcked");
    const errors = validateData(depositFrom);
    console.log("errors", errors);
    if (!Object.keys(errors).length) {
      createDeposit({ ...depositFrom, amount })
        .then((res) => {
          Toaster(APP_CONST.TOAST_TYPE.SUCCESS, res.data.data.message);
        })
        .catch((err) => {
          Toaster(APP_CONST.TOAST_TYPE.ERROR, err.response.data.message);
        });
    }
  };

  const handleFileChange = (e) => {
    const localFile = e.target.files?.[0];
    validateData({ ...depositFrom, ["txnImage"]: localFile });
    setDepositForm({ ...depositFrom, ["txnImage"]: localFile });
  };
  return (
    <>
      <HeaderAfterLogin />
      <main className="main">
        <div className="d-xl-none mt--14">
          <LeftBarSports />
        </div>
        <div className="transfer_info_page">
          <Container>
            <div className="tabs-area ">
              <Tabs>
                <Tab eventKey="first" title="Bank Transfer">
                  <>
                    <div className="d-flex justify-content-between mt-4">
                      {/* <h2 className="sectionTitle m-0">Deposit</h2> */}
                      {/* <div className="back-link" onClick={() => navigate(-1)}>
                        Back <BackIcon />
                      </div> */}
                    </div>

                    <Row className="mt-5 mb-4">
                      <Col lg={12}>
                        <div className="transfer_details p-3 px-md-5">
                          <h5 className="mt-3 mb-4 text-center">
                            Payment Transfer Details
                          </h5>
                          {bankDetails && (
                            <div>
                              <p>Bank name : {bankDetails?.bank_name}</p>
                              <p>Account name : {bankDetails?.acc_name}</p>
                              <p>IFSC code : {bankDetails?.ifsc_code}</p>
                              <p>Account number : {bankDetails?.acc_no}</p>

                              {/* <p className="pay-qr">Or Pay Using Qr Code</p>
                              <div className="qr">
                                {" "}
                                <img src={upiqr} />
                              </div> */}
                              <p>
                                <span className="note"> Note - </span>After
                                Transfer enter transaction reference number and
                                upload the screenshot below{" "}
                              </p>
                            </div>
                          )}

                          <Form>
                            <Row>
                              <Form.Group
                                className="form-group mb-4"
                                controlId="formGroupAmount"
                              >
                                <Form.Label>Amount*</Form.Label>
                                <Form.Control
                                  type="number"
                                  placeholder="Enter Amount"
                                  name="amount"
                                  value={amount}
                                  onChange={(e) => {
                                    setamount(e.target.value);
                                  }}
                                  isInvalid={!!formError.amount}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {formError.amount}
                                </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Group
                                className="form-group mb-4"
                                controlId="formGroupAmount"
                              >
                                <Form.Label>
                                  UTR or Transaction Ref Number*
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Enter UTR No"
                                  name="utrNo"
                                  onChange={handleChange}
                                  isInvalid={!!formError.utrNo}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {formError.utrNo}
                                </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Group
                                className="form-group mb-4"
                                controlId="formGroupAmount"
                              >
                                <Form.Label>Upload Screenshot File*</Form.Label>
                                <Form.Control
                                  type="file"
                                  onChange={handleFileChange}
                                  isInvalid={!!formError.txnImage}
                                  placeholder="Screenshot file"
                                />
                                <Form.Control.Feedback type="invalid">
                                  {formError.txnImage}
                                </Form.Control.Feedback>
                              </Form.Group>
                              <Col xs={12} className="text-center mt-2">
                                <butto
                                  onClick={handleSubmit}
                                  className="btn btn-primary py-2 px-5"
                                >
                                  Submit
                                </butto>
                              </Col>
                            </Row>
                          </Form>

                          {/* <div className="upi-list mt-5 mb-3">
                            <ul>
                              <li>
                                <a href="#">
                                  <img src="./images/upi.png" />
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <img src="./images/gpay.png" />
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <img src="./images/paytm.png" />
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <img src="./images/phone-pay.png" />
                                </a>
                              </li>
                            </ul>
                          </div> */}
                        </div>
                      </Col>

                      {/* <Col
                        lg={4}
                        className="d-none d-lg-flex justify-content-center offset-lg-1"
                      >
                        <div className="deposit_img">
                          <img src={DepositImg} alt="Deposit Illustration" />
                        </div>
                      </Col> */}
                      {/* <Col>
                        <div className="upi-qrr">
                          <img src={upiqr} />
                        </div>
                        <div className="id-upi">
                          UPI - poornabommanapally-1@okhdfcbank{" "}
                        </div>
                      </Col> */}
                    </Row>
                  </>
                  {/* <div className="bank-details">
                    <div className="top-area">
                      Make a deposit in the following account and submit the UTR
                      number.
                    </div>
                    <div className="bottom-area">
                      <table className="table">
                        <tr>
                          <th colSpan={2}>Bank Name Here</th>
                        </tr>
                        <tr>
                          <td>Account Name:</td>
                          <td>{bankDetails?.acc_name}</td>
                        </tr>
                        <tr>
                          <td>Account Nnumber:</td>
                          <td>{bankDetails?.acc_no}</td>
                        </tr>
                        <tr>
                          <td>IFSC:</td>
                          <td>{bankDetails?.ifsc_code}</td>
                        </tr>
                        <tr>
                          <td>Bank Name</td>
                          <td>{bankDetails?.bank_name}</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <Form>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Control type="text" placeholder="Amount*" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="">
                      <Form.Control type="text" placeholder="UTR*" />
                    </Form.Group>
                    <Button variant="primary" type="submit">
                      Submit
                    </Button>
                  </Form> */}
                  {/* <div className="agent-head">
                    <div
                      onClick={() =>
                        window.open("https://wa.me/+917888846060", "_blank")
                      }
                      className="social-icon watsapp"
                    >
                      <img src={Whatsapp} />
                    </div>
                    <a
                      href="https://t.me/JuwaGhar"
                      className="social-icon watsapp"
                    >
                      <img src={Telegram} />
                    </a>
                    <a
                      target="_blank"
                      href="https://www.facebook.com/juwaghar"
                      className="social-icon watsapp"
                    >
                      <img src={Facebook} />
                    </a>
                    <a
                      target="_blank"
                      href="https://www.instagram.com/JuwaGhar"
                      className="social-icon watsapp"
                    >
                      <img src={Instagram} />
                    </a>
                  </div> */}
                </Tab>
                {/* <Tab eventKey="second" title="UPI">
                  <div className="upiqr">
                    <img src={upiqr} />
                  </div>
                  <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Control
                        type="text"
                        placeholder="Amount*"
                        name="upiamount"
                        onChange={handleChange}
                        isInvalid={!!formError.upiamount}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formError.upiamount}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Button variant="primary" type="submit">
                      Pay
                    </Button>
                  </Form>
                  <div className="upi-list">
                    <ul>
                      <li>
                        <a href="#!">
                          <img src="./images/upi.png" alt="" />
                        </a>
                      </li>
                      <li>
                        <a href="#!">
                          <img src="./images/gpay.png" alt="" />
                        </a>
                      </li>
                      <li>
                        <a href="#!">
                          <img src="./images/paytm.png" alt="" />
                        </a>
                      </li>
                      <li>
                        <a href="#!">
                          <img src="./images/phone-pay.png" alt="" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </Tab> */}
              </Tabs>
            </div>
          </Container>
        </div>
      </main>
    </>
  );
};

export default BankDeposit;

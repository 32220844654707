import React from "react";
import Footer from "../../../../containers/Footer";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Whatsapp from "../../../../assets/images/Whatsapp.svg";
import Telegram from "../../../../assets/images/Telegram.svg";
import Instagram from "../../../../assets/images/Instagram.svg";
import { useSelector } from "react-redux";

const ContactUs = () => {
  const { appDetails } = useSelector((state) => state.app);
  console.log("appDetails", appDetails);
  return (
    <div>
      <div>
        <HeaderAfterLogin />
      </div>
      <div>
        <main className="main">
          <div className="container">
            <h2 className="sectionTitle">Contact Us</h2>
            <div className="payment-info">
              <div class="social-icons contact-social">
                <ul>
                  <li>
                    <div>
                      <a
                        className="panel social-icon watsapp"
                        href={
                          appDetails?.WHATS_APP_URL != ""
                            ? appDetails?.WHATS_APP_URL
                            : "#"
                        }
                        target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                      >
                        {" "}
                        <img src={Whatsapp} alt="Whatsapp Icon" />
                      </a>
                      {/* <span className="notification-count">2</span> */}
                    </div>
                  </li>
                  {/* <li>
                    <a target="_blank" href={appDetails?.TELEGRAM}>
                      <img src={Telegram} alt="Telegram Icon" />
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href={appDetails?.INSTATGRAM}>
                      <img src={Instagram} alt="Instagram Icon" />
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </main>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default ContactUs;

import React from "react";
import Whatsapp from "../../../../assets/images/Whatsapp.svg";
import Telegram from "../../../../assets/images/Telegram.svg";
import Instagram from "../../../../assets/images/Instagram.svg";
import Header from "../../../../containers/Header";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import { useSelector } from "react-redux";

const BeforeContactUs = () => {
  const { appDetails } = useSelector((state) => state.app);

  return (
    <div>
      <div>
        <Header />
      </div>
      <div>
        <main className="main">
          <div className="container">
            <h2 className="sectionTitle">Contact Us</h2>
            <div className="payment-info">
              <div class="social-icons contact-social">
                <ul>
                  <li>
                    <div>
                      <a
                        className="panel social-icon watsapp"
                        href={
                          appDetails?.WHATS_APP_URL != ""
                            ? appDetails?.WHATS_APP_URL
                            : "#"
                        }
                        target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                      >
                        {" "}
                        <img src={Whatsapp} alt="Whatsapp Icon" />
                      </a>
                    </div>
                  </li>
                  {/* <li>
                    <a target="_blank" href="https://t.me/JuwaGhar">
                      <img src={Telegram} alt="Telegram Icon" />
                    </a>
                  header</li>
                  <li>
                    <a
                      target="_blank"
                      href="https://www.instagram.com/JuwaGhar"
                    >
                      <img src={Instagram} alt="Instagram Icon" />
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </main>
      </div>
      <div>
        <BeforeLoginFooter />
      </div>
    </div>
  );
};

export default BeforeContactUs;

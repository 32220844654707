import React, { useEffect, useState } from "react";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import { socket } from "../../../../utils/socketUtil";
import { asyncSocketEmit } from "../../../../utils/socketUtil";
import { Toaster } from "../../../../utils/toaster";
import { APP_CONST } from "../../../../config/const";
import { useSelector } from "react-redux";
import Footer from "../../../../containers/Footer";
const PublicSportsBook = () => {
  const [iframeUrl, setIframeUrl] = useState("");
  const isAuth = useSelector((state) => state.auth.isAuth);
  var width = window.screen.width;
  const isMobile = width < 800;
  console.log("isMobile", isMobile);
  document.body.style.setProperty("--header-background", `#050505`);

  useEffect(() => {
    if (isAuth) {
      getUrl();
    }
  }, [isAuth]);

  // const getUrl = async () => {
  //   socket.emit(
  //     "get:gamblebeast_lobby",
  //     {
  //       mobile: isMobile,
  //     },
  //     (res) => {
  //       if (res?.status === "failed") {
  //         Toaster(APP_CONST.TOAST_TYPE.ERROR, res.message);
  //       }
  //       if (res?.status === "success") {
  //         setIframeUrl(res?.data);
  //       }
  //     }
  //   );
  // };

  //Enable Albert's sprotsbook
  const getUrl = async () => {
    socket.emit(
      "get:spbalbert_lobby",
      {
        mobile: isMobile,
        lang: "en",
      },
      (res) => {
        if (res?.status !== null) {
          Toaster(APP_CONST.TOAST_TYPE.ERROR, res.message);
        }
        if (res?.data?.lobbyUrl) {
          setIframeUrl(res?.data?.lobbyUrl);
        }
      }
    );
  };

  return (
    <>
      <HeaderAfterLogin />
      <div className="iframe-div">
        <iframe
          src={iframeUrl}
          width="100%"
          style={{ height: "100%", border: "0" }}
        />
        <Footer />
      </div>
    </>
  );
};
export default PublicSportsBook;
